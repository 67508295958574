<template>
	<div style="height: 100%">
		<ViewLayout className="seats">
			<template v-slot:body>
				<h1>{{ $store.strings.results_seatsTitle.replace('{name}', localizedContent(result.ceramic, $store.lang).name ? localizedContent(result.ceramic, $store.lang).name : '') }}</h1>
				<p v-if="$store.strings.results_seatsSelect">{{ $store.strings.results_seatsSelect }}</p>
				<p v-if="datasheet" v-html="datasheet"></p>

				<div class="uk-grid-small uk-child-width-1-2 uk-text-center" uk-grid>
					<!-- results -->
					<ResultCard
						v-for="seat in result.ceramic.seats"
						:key="seat.article"
						@click.native="showShops(seat)"
					>
						<template v-slot:image>
							<img :src="apiBase + seat.images[0].url" :alt="localizedContent(seat, $store.lang).name" />
						</template>
						<template v-slot:footer>
							<p class="seat-number">{{ seat.article }}</p>
							<p class="seat-description">{{ localizedContent(seat, $store.lang).fullDescription }}</p>
						</template>
					</ResultCard>
					<div class="spacer"></div>
				</div>
			</template>

			<template v-slot:footer>
				<router-link :to="'result/' + $store.requestID">
					<button
						class="uk-button uk-button-primary uk-button-large blue-action-button"
						onclick="_paq.push(['trackEvent', 'seats', 'back']);"
					>
						{{ $store.strings.results_backToCeramics }}
					</button>
				</router-link>
			</template>
		</ViewLayout>
	</div>
</template>
<script>
// @ is an alias to /src
import ViewLayout from '@/components/ViewLayout.vue'
import ResultCard from '@/components/ResultCard.vue'
import { localizedContent } from '../helper.js'
export default {
	name: 'Shop',
	data: function() {
		return {
			result: null,
			apiBase: process.env.VUE_APP_API_BASE,
		}
	},
	computed: {
		datasheet() {
			const locContent = localizedContent(this.result.ceramic, this.$store.lang)
			if (this.result && locContent.links && locContent.links.some(link => link.type === 'datasheet'))
				return this.$store.strings.results_seatsCompareDatasheet.replace(
					'{link}',
					'<a href="' + locContent.links.find(link => link.type === 'datasheet').url + '" onclick="_paq.push([\'trackEvent\', \'seats\', \'datasheet\']);" target="_blank">' + this.$store.strings.results_seatsDatasheet + '</a>'
				)
			else
				return false
		},
	},
	components: {
		ViewLayout,
		ResultCard,
	},
	methods: {
		localizedContent,
		showShops(seat) {
			window._paq.push(['trackEvent', 'seats', 'selection', seat.article])

			this.$router.push({
				name: 'Shop',
				params: {
					seat,
				},
			})
		},
	},
	created: function() {
		if (!this.$route.params.result) {
			this.$router.push('/')
			return
		}
		this.result = this.$route.params.result

		window._paq.push(['trackEvent', 'seats', 'matches', this.result.ceramic.seats.length])
	}
};
</script>
<style lang="scss" scoped>
.seat-number {
	font-weight: 700;
	margin: 0;
	font-size: 16px;
}
.seat-description {
	line-height: 1.8;
	font-size: 10px;
	margin: 0;
}
</style>
