<template>
	<div style="height: 100%">
		<ViewLayout className="shop">
			<template v-slot:body>
				<h1>{{ $store.strings.results_shopsTitle }}</h1>
				<p v-if="$store.strings.results_shopsSelect">{{ $store.strings.results_shopsSelect }}</p>

				<div class="shops-wrapper" v-if="shops.length">
					<ShopCard v-for="shop in shops" :key="shop.id" :shop="shop" />
					<ShopCard v-if="shopFinder" key="shopFinder" :shop="shopFinder" />
				</div>
				<div class="shops-wrapper no-shops" v-else>
					<ShopCard v-if="fallbackShop" key="fallback-shop" :shop="fallbackShop" />
					<ShopCard v-if="shopFinder" key="shop-finder" :shop="shopFinder" />
				</div>
			</template>
			<template v-slot:footer>
				<router-link :to="'result/' + $store.requestID">
					<button	class="uk-button uk-button-primary uk-button-large blue-action-button" onclick="_paq.push(['trackEvent', 'shop', 'back']);">
						{{ $store.strings.results_backToCeramics }}
					</button>
				</router-link>
			</template>
		</ViewLayout>
	</div>
</template>
<script>
import ViewLayout from '@/components/ViewLayout.vue'
import ShopCard from '@/components/ShopCard.vue'
import { localizedContent } from '../helper.js'
export default {
	name: 'Shop',
	data: function() {
		return {
			seat: null,
		}
	},
	computed: {
		shops() {
			const links = localizedContent(this.seat, this.$store.lang).links
			const preferredShop = this.$store.settings.shop
			if (preferredShop)
				return links.filter(link => link.shop && link.shop.slug.includes(preferredShop))
			else
				return links.filter(link => link.shop && link.shop.active)
		},
		fallbackShop() {
			if (this.$store.strings.results_fallbackShop && this.$store.strings.results_fallbackShop.shop)
				return {
					"url": this.$store.strings.results_fallbackShop.url.replace('{search}', encodeURIComponent(localizedContent(this.seat, this.$store.lang).name)),
					"type": "shop",
					"shop": {
						...this.$store.strings.results_fallbackShop.shop.data.attributes,
						"logo": {
							...this.$store.strings.results_fallbackShop.shop.data.attributes.logo.data.attributes,
						},
						"slug": "fallback-shop",
					},
				}
			else
				return false
		},
		shopFinder() {
			if (this.$store.strings.results_shopFinder && this.$store.strings.results_shopFinderURL)
				return {
					"url": this.$store.strings.results_shopFinderURL.url,
					"type": "shop",
					"shop": {
						"name": this.$store.strings.results_shopFinder,
						"slug": "shop-finder",
					},
				}
			else
				return false
		}
	},
	components: {
		ViewLayout,
		ShopCard,
	},

	created: function() {
		if (!this.$route.params.seat) {
			this.$router.push('/')
			return
		}
		this.seat = this.$route.params.seat
	},
}
</script>
<style lang="scss" scoped>
.shops-wrapper {
	align-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-items: center;
	padding: 15px;
	padding-top: 30px;
}
</style>
