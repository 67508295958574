<template>
	<div v-if="shop.shop.slug=='shop-finder'" @click="showShop(shop)" class="uk-flex uk-flex-center shop-button" :class="[shop.shop.slug, shop.shop.logo ? 'shop-logo' : null].filter(entry => !!entry).join(' ')" :style="shop.shop.logo ? 'background-image: url(' + apiBase + shop.shop.logo.url + ');' : ''">
		<span class="uk-flex uk-flex-middle" uk-icon="cart"></span> <span class="shop-name uk-flex uk-flex-middle uk-flex-center" v-if="shop.shop.name">{{ shop.shop.name }}</span>
	</div>
	<div v-else @click="showShop(shop)" class="uk-flex shop-button" :class="[shop.shop.slug, availabilityClass(shop.shopAvailability), shop.shop.logo ? 'shop-logo' : ''].filter(entry => !!entry).join(' ')" :style="shop.shop.logo ? 'background-image: url(' + apiBase + shop.shop.logo.url + ');' : ''">
		<span class="shop-name uk-flex uk-flex-middle uk-flex-center uk-flex-auto" v-if="shop.shop.name">{{ shop.shop.name }}</span>
		<span class="availability uk-badge" v-if="availabilityString(shop.shopAvailability)">{{ availabilityString(shop.shopAvailability) }}</span>
	</div>
</template>

<script>
export default {
	name: "ShopCard",
	data() {
		return {
			apiBase: process.env.VUE_APP_API_BASE,
		}
	},
	props: {
		shop: {
			type: Object
		}
	},
	methods: {
		showShop(shop) {
			window._paq.push(["trackEvent", "show", "selection", shop.shop.slug]);
			window.open(shop.url, "_blank");
		},
		availabilityClass(availability) {
			switch (availability) {
				case true:
					return 'available'
				case false:
					return 'unavailable'
			}
		},
		availabilityString(availability) {
			switch (availability) {
				case true:
					return this.$store.strings.results_available
				case false:
					return this.$store.strings.results_unavailable
				default:
					return this.$store.strings.results_unknownAvailability
			}
		},
	}
};
</script>

<style scoped lang="scss">
.shop-button {
	--availibility: #{$global-primary-background};
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center center;
	border-radius: 50px;
	border: solid 2px var(--primary-color);
	cursor: pointer;
	height: 50px;
	margin-bottom: 40px;
	margin-bottom: 20px;
	max-width: 90%;
	min-width: 180px;
	order: 2;
	overflow: hidden;
	position: relative;
	text-align: center;
	width: 240px;

	.uk-icon {
		color: var(--primary-color);
		margin-right: 0.25em;
		transform: translateY(-0.1em);
	}

	.shop-name {
		color: var(--primary-color);
		font-size: 1.5rem;
		overflow: hidden;
		text-overflow: ellipsis;
		padding: 0.25em 0.5em;
	}
	&.shop-logo .shop-name {
		display: none;
	}
	&.shop-finder .shop-name {
		font-size: 0.875rem;
	}

	&.available {
		--availibility: green;
		order: 1;
	}
	&.unavailable {
		display: none;
		--availibility: gray;
		order: 3;
	}

	.availability {
		display: none;
		background-color: var(--availibility);
		background-image: linear-gradient(180deg, rgba(0,0,0,0.333) 0%, rgba(0,0,0,0) 15%, rgba(0,0,0,0) 100%);
		border-radius: 1em;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		height: auto;
		line-height: 1em;
		max-width: 80%;
		padding: 0.6em 1.2em 0.5em;
		position: relative;
			top: 100%;
		z-index: -1;
	}

	@media (hover: hover) and (pointer: fine) {
		.availability {
			transition: top 0.5s 1s ease-out;
			top: 0%;
		}
		&:hover .availability,
		&:focus .availability,
		&:active .availability {
			transition-delay: 0s;
			top: 100%;
		}
	}
}
</style>
